import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { Button, Input } from "../CustomComponents";
import styles from "../CustomComponents/index.module.css";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editMcqModal.module.scss";
import { useSelector } from "react-redux";
import { useGetAudienceGroupsQuery } from "../../Data/services/audienceGroupApi";
import { useGetQuestionGroupsQuery } from "../../Data/services/questionGroupApi";

const statusList = [
  { label: "Active", value: "active" },
  { label: "In active", value: "inactive" },
  { label: "Completed", value: "completed" },
];

const EditSessionModal = ({
  handleEditModalClose,
  data,
  handleAdd,
  handleUpdate,
  updateLoading,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      start_time: "",
      end_time: "",
      date: "",
      status: "active",
      audience_group_id: "",
      question_group_id: "",
    },
  });

  const {} = useGetAudienceGroupsQuery({});
  const {} = useGetQuestionGroupsQuery({});

  var audienceData = useSelector((state) => state.audienceGroup.audience);
  var questionData = useSelector((state) => state.questionGroup.question);

  const [flag, setFlag] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      try {
        // Parse date in EST first
        const estDateTimeStart = moment.tz(
          `${data?.date} ${data.start_time}`,
          "YYYY-MM-DD HH:mm:ss",
          "America/New_York"
        );
        const estDateTimeEnd = moment.tz(
          `${data?.date} ${data.end_time}`,
          "YYYY-MM-DD HH:mm:ss",
          "America/New_York"
        );

        // Convert to user's local timezone
        const localDateTimeStart = estDateTimeStart.clone().local();
        const localDateTimeEnd = estDateTimeEnd.clone().local();

        // Format date separately
        const localDate = estDateTimeStart.clone().local().format("YYYY-MM-DD");

        // Setting values
        setValue("name", data?.name);
        setValue("description", data?.description);
        setValue("start_time", localDateTimeStart.format("HH:mm:ss")); // Local time
        setValue("end_time", localDateTimeEnd.format("HH:mm:ss")); // Local time
        setValue("date", localDate); // Local date
        setValue("status", data?.status);
        setValue("audience_group_id", data?.audience_group_id);
        setValue("question_group_id", data?.question_group_id);
        setFlag(!flag);
      } catch (e) {
        console.log("Something went wrong!");
      }
    }
  }, [data]);

  // const onSubmit = async (record) => {
  //   try {
  //     const body = {
  //       ...record,
  //       start_time: moment(`${record?.date} ${record.start_time}`)
  //         .tz("America/New_York")
  //         .format("HH:mm:ss"),
  //       end_time: moment(`${record?.date} ${record.end_time}`)
  //         .tz("America/New_York")
  //         .format("HH:mm:ss"),
  //       date: record?.date,
  //       audience_group_id: record?.audience_group_id || null,
  //       question_group_id: record?.question_group_id || null,
  //     };

  //     if (!_.isEmpty(data)) {
  //       handleUpdate(body);
  //     } else {
  //       handleAdd(body);
  //     }
  //   } catch (e) {
  //     console.log(e.message);
  //   }
  // };
  const onSubmit = async (record) => {
    try {
      // Convert the given date & time (from user's local input) to EST
      const startDateTime = moment.tz(
        `${record?.date} ${record.start_time}`,
        "YYYY-MM-DD HH:mm:ss",
        moment.tz.guess()
      ); // Detect user's timezone
      const endDateTime = moment.tz(
        `${record?.date} ${record.end_time}`,
        "YYYY-MM-DD HH:mm:ss",
        moment.tz.guess()
      );

      // Convert to America/New_York (EST)
      const estStartDateTime = startDateTime.clone().tz("America/New_York");
      const estEndDateTime = endDateTime.clone().tz("America/New_York");

      const body = {
        ...record,
        start_time: estStartDateTime.format("HH:mm:ss"), // Always save time in EST
        end_time: estEndDateTime.format("HH:mm:ss"), // Always save time in EST
        date: estStartDateTime.format("YYYY-MM-DD"), // Ensure date is in EST
        audience_group_id: record?.audience_group_id || null,
        question_group_id: record?.question_group_id || null,
      };

      if (!_.isEmpty(data)) {
        handleUpdate(body);
      } else {
        handleAdd(body);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0 black-text">
              {data ? "Edit Session" : "Add Session"}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={12} sm={12} className="mb-3">
                  <p className="m-0 black-text">Session Name</p>
                  <Controller
                    control={control}
                    name="name"
                    rules={{
                      required: "Session name is required.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mt-2 "
                        type="text"
                        placeholder="Enter question"
                        value={value}
                        onChange={onChange}
                        name="name"
                      />
                    )}
                  />

                  {errors?.name?.message && (
                    <p className="m-0 text-danger">{errors?.name?.message}</p>
                  )}
                </Col>

                <Col md={12} sm={12} className="mb-3">
                  <p className="m-0 black-text">Description</p>
                  <Controller
                    control={control}
                    name="description"
                    rules={{
                      required: "Description is required.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mt-2"
                        type="text"
                        placeholder="Enter description"
                        value={value}
                        onChange={onChange}
                        name="description"
                      />
                    )}
                  />
                  {errors?.description?.message && (
                    <p className="m-0 text-danger">
                      {errors?.description?.message}
                    </p>
                  )}
                </Col>

                <Col md={12}>
                  <p className="m-0 black-text">Session Start Time</p>
                  <Controller
                    control={control}
                    name="start_time"
                    rules={{
                      required: "Start time is required.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mb-2"
                        type="time"
                        placeholder="Enter Start time"
                        value={value}
                        onChange={onChange}
                        name="start_time"
                      />
                    )}
                  />
                </Col>

                <Col md={12}>
                  <p className="m-0 black-text">Session End Time</p>
                  <Controller
                    control={control}
                    name="end_time"
                    rules={{
                      required: "Start time is required.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mb-2"
                        type="time"
                        placeholder="Enter End time"
                        value={value}
                        onChange={onChange}
                        name="end_time"
                      />
                    )}
                  />
                </Col>

                <Col md={12}>
                  <p className="m-0 black-text">Session Date</p>

                  <Controller
                    control={control}
                    name="date"
                    rules={{
                      required: "Date is required.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mb-2"
                        type="date"
                        placeholder="Enter End time"
                        value={value}
                        onChange={onChange}
                        name="date"
                      />
                    )}
                  />
                </Col>

                <Col md={12} className="my-2">
                  <Row>
                    <Col xs={12} md={6}>
                      <p className="m-0 black-text">Select Audience Group</p>
                      <Controller
                        control={control}
                        name="audience_group_id"
                        value={getValues("audience_group_id")}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <select
                            className={`${styles.Input} w-100`}
                            onChange={onChange}
                            value={getValues("audience_group_id")}
                          >
                            <option value={""}>Select Option</option>
                            {!!audienceData?.length &&
                              audienceData.map(({ id, title }, index) => (
                                <option key={index} value={id}>
                                  {title}
                                </option>
                              ))}
                          </select>
                        )}
                      />
                      {errors?.audience_group_id?.message && (
                        <p className="m-0 text-danger">
                          {errors?.audience_group_id?.message}
                        </p>
                      )}
                    </Col>

                    <Col xs={12} md={6}>
                      <p className="m-0 black-text">Select Question Group</p>
                      <Controller
                        control={control}
                        name="question_group_id"
                        value={getValues("question_group_id")}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <select
                            className={`${styles.Input} w-100`}
                            onChange={onChange}
                            value={getValues("question_group_id")}
                          >
                            <option value={""}>Select Option</option>
                            {!!questionData?.length &&
                              questionData.map(({ id, title }, index) => (
                                <option key={index} value={id}>
                                  {title}
                                </option>
                              ))}
                          </select>
                        )}
                      />
                      {errors?.question_group_id?.message && (
                        <p className="m-0 text-danger">
                          {errors?.question_group_id?.message}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col md={12}>
                  <p className="m-0 black-text">Status</p>
                  <Controller
                    control={control}
                    name="status"
                    rules={{
                      required: "Status is required.",
                    }}
                    value={getValues("status")}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        className={`${styles.Input} w-100`}
                        onChange={onChange}
                        value={getValues("status")}
                      >
                        {!!statusList?.length &&
                          statusList.map(({ label, value }, index) => (
                            <option key={index} value={value}>
                              {label}
                            </option>
                          ))}
                      </select>
                    )}
                  />
                  {errors?.description?.message && (
                    <p className="m-0 text-danger">
                      {errors?.description?.message}
                    </p>
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 ${
                      updateLoading ? "bg-dark-subtle" : ""
                    }`}
                    text={
                      updateLoading ? (
                        <BeatLoader size={10} />
                      ) : data ? (
                        "Update"
                      ) : (
                        "Save"
                      )
                    }
                    type="Submit"
                  ></Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditSessionModal;

{
  /* <Form onSubmit={handleSubmit(handleAddDateTimeInData)}>
              <Row>
                <Col md={6} sm={12}>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 black-text">Session Name</p>
                      <Controller
                        control={control}
                        name="name"
                        rules={{
                          required: "Session name is required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="text"
                            placeholder="Enter question"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />

                      {errors?.name?.message ? (
                        <p className="m-0 text-danger text-center">
                          {errors?.name?.message}
                        </p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <p className="m-0 black-text">Description</p>
                      <Controller
                        control={control}
                        name="description"
                        rules={{
                          required: "Description is required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="text"
                            placeholder="Enter description"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                      {errors?.description?.message ? (
                        <p className="m-0 text-danger text-center">
                          {errors?.description?.message}
                        </p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}></Col>
                  </Row>
                </Col>

                <Col md={6} sm={12}>
                  <Row>
                    
                    <Col md={6}>
                      <p className="m-0 black-text">Round 1 Start at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round1Start}
                        onChange={handleRound1Start}
                      />
                    </Col>
                    
                    <Col md={6}>
                      <p className="m-0 black-text">Round 1 End at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round1End}
                        onChange={handleRound1End}
                      />
                    </Col>

                  </Row>
                  <Row>
                    
                    <Col md={6}>
                      <p className="m-0 black-text">Round 2 Start at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round2Start}
                        onChange={handleRound2Start}
                      />
                    </Col>
                    
                    <Col md={6}>
                      <p className="m-0 black-text">Round 2 End at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round2End}
                        onChange={handleRound2End}
                      />
                    </Col>
                    
                  </Row>
                  <Row>
                    
                    <Col md={6}>
                      <p className="m-0 black-text">Round 3 Start at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round3Start}
                        onChange={handleRound3Start}
                      />
                    </Col>
                    
                    <Col md={6}>
                      <p className="m-0 black-text">Round 3 End at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round3End}
                        onChange={handleRound3End}
                      />
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p className="m-0 black-text">Round 4 Start at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round4Start}
                        onChange={handleRound4Start}
                      />
                    </Col>
                    <Col md={6}>
                      <p className="m-0 black-text">Round 4 End at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round4End}
                        onChange={handleRound4End}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              
              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 ${
                      updateLoading ? "bg-dark-subtle" : ""
                    }`}
                    text={
                      updateLoading ? (
                        <BeatLoader size={10} />
                      ) : data ? (
                        "Update"
                      ) : (
                        "Save"
                      )
                    }
                    type="Submit"
                  ></Button>
                </Col>
              </Row>
            </Form> */
}
