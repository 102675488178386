import { createSlice } from "@reduxjs/toolkit";
import { audienceGroupApi } from "../services/audienceGroupApi";

const initialState = {
  audience: [],
};

export const audienceGroupSlice = createSlice({
  name: "session",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        audienceGroupApi.endpoints.getAudienceGroups.matchFulfilled,
        (state, { payload }) => {
          state.audience = payload.data;
        }
      )
      .addMatcher(
        audienceGroupApi.endpoints.getAudienceGroups.matchRejected,
        (state) => {
          state.audience = [];
        }
      );
  },
});

export const {} = audienceGroupSlice.actions;

export default audienceGroupSlice.reducer;
