import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { FaUserEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { useSelector } from "react-redux";
import { ShimmerTable } from "react-shimmer-effects";
import { Button, SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import EditAddAudienceModal from "../../Components/Modals/EditAddAudienceModal";
import RecordNotFound from "../../Components/RecordNotFound";
import Table from "../../Components/Table/Table";
import {
  useAddAudienceGroupMutation,
  useDeleteAudienceGroupMutation,
  useGetAudienceGroupsQuery,
  useUpdateAudienceGroupMutation,
} from "../../Data/services/audienceGroupApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/audience_group_management.module.scss";

const AudienceGroupManagement = () => {
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const { isLoading, refetch } = useGetAudienceGroupsQuery({
    refetchOnMountOrArgChange: true,
  });

  var audienceData = useSelector((state) => state.audienceGroup.audience);
  console.log(audienceData);

  useEffect(() => {
    setRowData(audienceData);
  }, [audienceData]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const handleAddUser = () => {
    setEditModal(true);
  };

  const [deleteAudienceGroup, { isLoading: isDeleting }] =
    useDeleteAudienceGroupMutation();

  const deleteData = async () => {
    try {
      const id = editedData.id;

      const { data } = await deleteAudienceGroup(id);

      if (data.code === 200) {
        SuccessToast(data?.message);
        handleDeleteModalClose();
        await refetch();
      }
    } catch (error) {
      console.log(error);
      Toast("Sorry Something went wrong", "error");
    }
  };

  const [addAudienceGroup] = useAddAudienceGroupMutation();

  const handleAdd = async (data) => {
    try {
      const response = await addAudienceGroup(data);

      if (response?.error?.data?.code === 400) {
        Toast(response?.error?.data?.message, "error");
        return;
      }

      if (response?.data?.code === 200) {
        await refetch();
        handleEditModalClose();
        SuccessToast(response?.data?.message);
      }
    } catch (error) {
      Toast("Sorry Something went wrong", "error");
    }
  };

  const [updateAudienceGroup, { isLoading: updateLoading }] =
    useUpdateAudienceGroupMutation();

  const handleUpdate = async (formData) => {
    try {
      const response = await updateAudienceGroup({
        modifiedData: formData,
        id: editedData.id,
      });
      console.log(response);

      if (response?.data?.code === 200) {
        await refetch();
        handleEditModalClose();
        SuccessToast(response?.data?.message);
      }
    } catch (error) {
      console.log(error);
      Toast("Sorry Something went wrong", "error");
    }
  };

  function indication() {
    return isLoading ? <ShimmerTable row={5} col={4} /> : <RecordNotFound />;
  }

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "module_type",
      text: "Type",
      sort: true,
      headerStyle: {
        width: "80px",
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "140px",
      },
      formatter: (cellContent, row) => {
        if (row.id) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div
                className={`${styles.btnBg} d-flex justify-content-center align-items-center black-text rounded-3 mx-2 p-2 `}
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleEditModalOpen(true);
                }}
              >
                <span
                  className={`align-items-center d-flex justify-content-center pe-1 `}
                >
                  <FaUserEdit />
                </span>
                <span>Edit</span>
              </div>
              <div
                className="d-flex justify-content-center align-items-center bg-danger white-text rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal && (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
          isDeleting={isDeleting}
        />
      )}

      {editModal && (
        <EditAddAudienceModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
          handleAdd={handleAdd}
          handleUpdate={handleUpdate}
          updateLoading={updateLoading}
        />
      )}

      <div className={styles.headerContainer}>
        <SubTitle text={"Audience Group Management"} classes="black-text" />

        <Row>
          <Col md={12}>
            <Button
              classes={`${styles.cardBtn} fs-6 px-4`}
              text="Add Audience Group"
              onClick={handleAddUser}
            ></Button>
          </Col>
        </Row>
      </div>

      <div className={styles.mainTable}>
        <Table data={rowData || []} columns={columns} indication={indication} />
      </div>
    </div>
  );
};

export default AudienceGroupManagement;
