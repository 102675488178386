import { baseApi } from "./baseApi";

export const audienceGroupApi = baseApi.injectEndpoints({
  reducerPath: "audienceGroupApi",
  endpoints: (builder) => ({
    addAudienceGroup: builder.mutation({
      query: (data) => {
        return {
          url: "api/admin/group/audience",
          method: "POST",
          body: data,
          headers: {},
        };
      },
    }),
    updateAudienceGroup: builder.mutation({
      query: ({ modifiedData, id }) => {
        return {
          url: `api/admin/group/${id}/audience`,
          method: "PUT",
          body: modifiedData,
          headers: {},
        };
      },
    }),
    deleteAudienceGroup: builder.mutation({
      query: (id) => {
        return {
          url: `api/admin/group/${id}`,
          method: "DELETE",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
    }),
    getAudienceGroups: builder.query({
      query: () => {
        return {
          url: "api/admin/group/audience",
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
    }),
    getUserList: builder.query({
      query: ({ query }) => {
        return {
          url: `api/admin/users?q=${query}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
    }),
  }),
});

export const {
  useAddAudienceGroupMutation,
  useUpdateAudienceGroupMutation,
  useDeleteAudienceGroupMutation,
  useGetAudienceGroupsQuery,
  useGetUserListQuery,
} = audienceGroupApi;
