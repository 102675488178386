import { createSlice } from "@reduxjs/toolkit";
import { questionGroupApi } from "../services/questionGroupApi";

const initialState = {
  question: [],
};

export const questionGroupSlice = createSlice({
  name: "questionGroupSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        questionGroupApi.endpoints.getQuestionGroups.matchFulfilled,
        (state, { payload }) => {
          state.question = payload.data;
        }
      )
      .addMatcher(
        questionGroupApi.endpoints.getQuestionGroups.matchRejected,
        (state) => {
          state.question = [];
        }
      );
  },
});

export const {} = questionGroupSlice.actions;

export default questionGroupSlice.reducer;
