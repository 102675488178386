import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FaCircleXmark, FaPlus, FaTrash } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { Button, Checbox, DecimalInput, Input } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editMcqModal.module.scss";

const EditMcqModal = ({
  handleEditModalClose,
  data,
  handleAdd,
  handleUpdate,
  updateLoading,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      options: data
        ? data.options || []
        : [
            { option: "", is_correct: false },
            { option: "", is_correct: false },
            { option: "", is_correct: false },
            { option: "", is_correct: false },
          ],
      module_type: "mcqs",
    },
  });

  // console.log(errors);

  const options = watch("options");

  const { fields, prepend, insert, remove, update } = useFieldArray({
    control,
    name: "options",
    defaultValues: [{ option: "" }],
  });

  const [selectedStatus, setSelectedStatus] = useState("");

  const statusList = [
    { label: "Active", value: "active" },
    { label: "InActive", value: "inactive" },
  ];

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (data) {
      // console.log(data);
      if (data.status === "active") {
        setSelectedStatus("Active");
      } else {
        setSelectedStatus("InActive");
      }
      setValue("status", data.status);
      setValue("question", data.question);
      setValue("description", data.description);
      setValue("score", data.score);
      setValue("time_limit", data.time_limit);

      data.question_options.forEach((option, index) => {
        update(index, { option: option.option, is_correct: option.is_correct });
      });
    }
  }, [data, mounted]);

  // Validation inside handleSubmit
  const validateOptions = (options) => {
    // const options = data.options;
    // console.log(options);

    // Check if at least one option is marked as correct
    const isCorrectSelected = options.some(
      (option) => option.is_correct === true
    );
    // console.log(isCorrectSelected);
    if (!isCorrectSelected) {
      setError("correct_answer", {
        type: "manual",
        message: "At least one correct option must be selected",
      });
      return false;
    } else {
      clearErrors("correct_answer");
    }

    // If both validations pass
    return true;
  };

  const handleCustomValidation = async (formData) => {
    // Perform custom validation
    // console.log(formData);

    const options = formData.options;

    const isValid = await validateOptions(options);
    // console.log(isValid);
    // formData.time_limit = 10;
    if (isValid) {
      if (data) {
        handleUpdate(formData); // Update existing MCQ
      } else {
        handleAdd(formData); // Add new MCQ
      }
    }
  };

  if (!mounted) {
    return;
  }

  return (
    <ModalContainer handleClose={handleEditModalClose}>
      <div className={cssStyles.modalContainer}>
        <div className="d-flex align-items-center w-100 justify-content-between mb-4">
          <p className="fs-4 fw-medium m-0 black-text">
            {data ? "Edit MCQ's" : "Add MCQ's"}
          </p>
          <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
            <FaCircleXmark
              role="button"
              onClick={handleEditModalClose}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
        <div className="w-100">
          <Form onSubmit={handleSubmit(handleCustomValidation)}>
            <Row>
              <Col md={6} sm={12}>
                <Row>
                  <Col md={12}>
                    <p className="m-0 black-text">Question</p>
                    <Controller
                      control={control}
                      name="question"
                      rules={{
                        required: "Question is required.",
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Input
                          classes="mt-2 mb-3"
                          type="text"
                          placeholder="Enter question"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors?.question?.message ? (
                      <p className="m-0 text-danger text-center">
                        {errors?.question?.message}
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p className="m-0 black-text">Description</p>
                    <Controller
                      control={control}
                      name="description"
                      rules={{}}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Input
                          classes="mt-2 mb-3"
                          type="text"
                          placeholder="Enter description"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Col>
                </Row>
                {
                  //   <Row>
                  //   <Col md={12}>
                  //     <p className="m-0 black-text">Type</p>
                  //     <Controller
                  //       control={control}
                  //       name="type"
                  //       rules={{}}
                  //       defaultValue={selectedType}
                  //       render={({
                  //         field: { onChange, onBlur, value, ref },
                  //       }) => (
                  //         <SelectFieldComponent
                  //           firstOption={
                  //             _.isEmpty(selectedType)
                  //               ? "Select Type"
                  //               : selectedType
                  //           }
                  //           value={value}
                  //           optionsList={questionType}
                  //           onSelectChange={(value) => {
                  //             const strToInt = parseInt(value);
                  //             console.log(strToInt);
                  //             onChange(strToInt);
                  //           }}
                  //         />
                  //       )}
                  //     />
                  //   </Col>
                  // </Row>
                }
                {/* <Row>
                  <Col md={12}>
                    <p className="m-0 black-text">Score</p>
                    <Controller
                      control={control}
                      name="score"
                      rules={{
                        required: "Score is required.",
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DecimalInput
                          classes="mt-2 mb-3"
                          placeholder="Enter score"
                          value={value}
                          onChange={(value) => {
                            onChange(value);
                          }}
                        />
                      )}
                    />
                    {errors?.score?.message ? (
                      <p className="m-0 text-danger text-center">
                        {errors?.score?.message}
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row> */}
                <Row>
                  <Col lg={6}>
                    <p className="m-0 black-text">Status</p>
                    <Controller
                      control={control}
                      name="status"
                      rules={{
                        required: "Status is required.",
                      }}
                      defaultValue={selectedStatus}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <SelectFieldComponent
                          firstOption={
                            _.isEmpty(selectedStatus)
                              ? "Select Status"
                              : selectedStatus
                          }
                          optionsList={statusList}
                          onSelectChange={(value) => {
                            // const strToInt = parseInt(value);
                            // console.log(strToInt);
                            // onChange(strToInt);
                            onChange(value);
                          }}
                        />
                      )}
                    />
                    {errors?.status?.message ? (
                      <p className="m-0 text-danger text-center">
                        {errors?.status?.message}
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col lg={6}>
                    <p className="m-0 black-text">Time Limit</p>
                    <Controller
                      control={control}
                      name="time_limit"
                      rules={{
                        required: "Time Limit is required.",
                      }}
                      defaultValue={30}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Input
                          classes="mt-2"
                          type="number"
                          placeholder="Enter time left"
                          value={value}
                          onChange={(value) => {
                            // console.log(value);
                            onChange(value);
                          }}
                        />
                      )}
                    />
                    <span className="gray-text fs-8">
                      (in seconds) by default 30 seconds
                    </span>
                    {errors?.time_limit?.message ? (
                      <p className="m-0 text-danger text-center">
                        {errors?.time_limit?.message}
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Button
                      classes={`${cssStyles.cardBtn} mt-4 ${
                        updateLoading ? "bg-dark-subtle" : ""
                      }`}
                      text={
                        updateLoading ? (
                          <BeatLoader size={10} />
                        ) : data ? (
                          "Update"
                        ) : (
                          "Save"
                        )
                      }
                      type="Submit"
                    ></Button>
                  </Col>
                </Row>
              </Col>
              <Col md={6} sm={12}>
                <Row>
                  <Col md={12}>
                    <p className="m-0 black-text">
                      Options
                      <span className="gray-text">
                        (check only correct answer)
                      </span>
                    </p>
                    {fields.map((field, index) => (
                      <Row key={field.id} className="mb-2">
                        <Col md={12}>
                          <Controller
                            control={control}
                            name={`options.${index}.option`}
                            rules={{ required: "Option is required." }}
                            render={({ field: { onChange, value } }) => (
                              <InputGroup className="d-flex justify-content-between align-items-center flex-row gap-3">
                                {index === 0 ? (
                                  <Button
                                    classes="btn px-3 black-text input-before-icon rounded-5 border-0 green-text"
                                    text={<FaPlus />}
                                    // onClick={() => append({ option: "" })}
                                    onClick={() =>
                                      insert(index, { option: "" })
                                    }
                                    // onClick={() => prepend({ option: "" })}
                                  />
                                ) : (
                                  ""
                                )}
                                <Input
                                  type="text"
                                  // placeholder={`Enter option ${ index + 1}`}
                                  placeholder={`Enter option ${
                                    fields.length - index
                                  }`}
                                  value={value}
                                  onChange={onChange}
                                />

                                <Controller
                                  control={control}
                                  name={`options.${index}.is_correct`}
                                  render={({ field: checkboxField }) => (
                                    <div className={`${cssStyles.checkbox}`}>
                                      <Checbox
                                        checked={checkboxField.value || false}
                                        onChange={() => {
                                          fields.forEach((_, idx) => {
                                            setValue(
                                              `options.${idx}.is_correct`,
                                              idx === index
                                            );
                                          });
                                          validateOptions(options);
                                        }}
                                      />
                                    </div>
                                  )}
                                />

                                {index > 0 ? (
                                  <Button
                                    type="text"
                                    className="btn px-3 red-text input-after-icon rounded-5 border-0"
                                    text={<FaTrash />}
                                    onClick={() => remove(index)}
                                  />
                                ) : (
                                  ""
                                )}
                              </InputGroup>
                            )}
                          />
                          {errors?.options &&
                            (errors.options[index] ? (
                              <p className="m-0 text-danger text-center">
                                {errors?.options[index].option.message}
                              </p>
                            ) : (
                              ""
                            ))}
                        </Col>
                      </Row>
                    ))}
                    {errors?.correct_answer && (
                      <p className="m-0 text-danger text-center">
                        {errors?.correct_answer?.message}
                      </p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </ModalContainer>
  );
};

export default EditMcqModal;
