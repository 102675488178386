import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { Button, Input } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editMcqModal.module.scss";
import Select from "react-select";
import { useGetUserListQuery } from "../../Data/services/audienceGroupApi";

const EditAddAudienceModal = ({
  handleEditModalClose,
  data,
  handleAdd,
  handleUpdate,
  updateLoading,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      ids: [],
    },
  });

  const [searchUser, setSearchUser] = useState("");

  const {
    isLoading,
    data: userList,
    refetch,
  } = useGetUserListQuery({
    query: searchUser,
  });

  const [userLists, setUserLists] = useState([]);

  const [flag, setFlag] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      try {
        setValue("title", data?.title);
        setValue(
          "ids",
          data.audience_groups.map((v) => ({
            value: v.id,
            label: v?.name ? v?.name : v?.firstname + " " + v?.lastname,
          }))
        );
        setFlag(!flag);
      } catch (e) {
        console.log("something went wrong !");
      }
    }
  }, [data]);

  useEffect(() => {
    try {
      let list = userList?.data;

      list = list?.map(({ id, name, firstname, lastname }) => ({
        value: id,
        label: name ? name : firstname + " " + lastname,
      }));

      setUserLists(list);
    } catch (e) {
      console.log(e.message);
    }
  }, [userList?.data]);

  const onSubmit = async (record) => {
    try {
      record = { ...record, ids: record?.ids.map((v) => v?.value) };

      if (!_.isEmpty(data)) {
        handleUpdate(record);
      } else {
        handleAdd(record);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const onChangeMultipe = (value) => {
    setValue("ids", value);
    setFlag(!flag);
  };

  const onSearch = (value) => {
    setSearchUser(value);
  };

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0 black-text">
              {data ? "Edit Audience" : "Create Audience Group"}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={12} sm={12} className="mb-3">
                  <p className="m-0 black-text">Group Name</p>
                  <Controller
                    control={control}
                    name="title"
                    rules={{
                      required: "Group name is required.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mt-2 "
                        type="text"
                        placeholder="Enter question"
                        value={value}
                        onChange={onChange}
                        name="title"
                      />
                    )}
                  />

                  {errors?.name?.message && (
                    <p className="m-0 text-danger">{errors?.name?.message}</p>
                  )}
                </Col>

                <Col md={12} sm={12} className="mb-3">
                  <p className="m-0 black-text">Select Users</p>
                  <Controller
                    control={control}
                    name="ids"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        closeMenuOnSelect={false}
                        menuPortalTarget={document.body}
                        isMulti
                        options={userLists}
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                            pointerEvents: "all",
                          }),
                        }}
                        onChange={(e) => onChangeMultipe(e)}
                        value={getValues("ids")}
                        onInputChange={onSearch}
                      />
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 ${
                      updateLoading ? "bg-dark-subtle" : ""
                    }`}
                    text={
                      updateLoading ? (
                        <BeatLoader size={10} />
                      ) : data ? (
                        "Update"
                      ) : (
                        "Save"
                      )
                    }
                    type="Submit"
                  ></Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditAddAudienceModal;
