import { baseApi } from "./baseApi";

export const questionGroupApi = baseApi.injectEndpoints({
  reducerPath: "questionGroupApi",
  endpoints: (builder) => ({
    addQuestionGroup: builder.mutation({
      query: (data) => {
        return {
          url: "api/admin/group/question",
          method: "POST",
          body: data,
          headers: {},
        };
      },
    }),
    updateQuestionGroup: builder.mutation({
      query: ({ modifiedData, id }) => {
        return {
          url: `api/admin/group/${id}/question`,
          method: "PUT",
          body: modifiedData,
          headers: {},
        };
      },
    }),
    deleteQuestionGroup: builder.mutation({
      query: (id) => {
        return {
          url: `api/admin/group/${id}`,
          method: "DELETE",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
    }),
    getQuestionGroups: builder.query({
      query: () => {
        return {
          url: "api/admin/group/question",
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
    }),
    getQuestionList: builder.query({
      query: () => {
        return {
          url: "api/admin/question",
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
    }),
  }),
});

export const {
  useAddQuestionGroupMutation,
  useUpdateQuestionGroupMutation,
  useDeleteQuestionGroupMutation,
  useGetQuestionGroupsQuery,
  useGetQuestionListQuery,
} = questionGroupApi;
